<template>
  <section class="ftr-bottom ftr__section">
    <p class="ftr-bottom__copyright">
      <span>&copy; {{ $t('footer.copyright') }} 2017 - {{ currentYear }} {{ $t('metadata.titleSubfix') }} </span>
      <span>{{ $t('footer.allRights') }}</span>
    </p>
    <app-footer-country country="hk" />
  </section>
</template>

<script>
import AppFooterCountry from './appFooterCountry'

export default {
  components: {
    'app-footer-country': AppFooterCountry,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
}
</script>

<style lang="postcss" scoped>
.ftr-bottom__copyright {
  @apply flex flex-wrap whitespace-pre-wrap;
  @apply text-grey-medium;

  & span {
    @apply text-xs;
  }
}

@screen mobile {
  .ftr-bottom__copyright {
    @apply justify-center px-4 text-center;
    @apply mb-2;
  }
}

@screen desktop {
  .ftr-bottom__copyright {
    @apply flex-grow items-center;
  }
}
</style>
