<template>
  <div :id="job._id" class="jc border-box">
    <!-- Overlaying job link -->
    <!--  REMARK: event="" avoid built-in click behaviour to fire route change  -->
    <!--  Reference: https://stackoverflow.com/questions/59885664/nuxt-how-to-prevent-nuxt-link-goes-to-another-page  -->
    <!--  Router Link source code: https://github.com/vuejs/vue-router/blob/6ec0ee563898ed513556f589209e8456d54ccd3b/src/components/link.js  -->
    <n-link
      class="jc__link"
      :title="jobName"
      event=""
      :to="localePathName(`/job/${jobSlug}/${job._id}`)"
      @click.native="onClick"
      >{{ jobName }}</n-link
    >
    <!-- Job card content -->
    <div class="jc__content">
      <div v-if="employmentType" class="jc__tags">
        <slot name="job-ad" />
        <emp-type :name="employmentType" />
      </div>
      <div class="jc__cover">
        <jc-text :company-name="companyName" :districts-label="districtsLabel" :job-name="jobName" />
        <jc-logo :name="companyName" :src="companyLogo" />
      </div>
      <p v-if="salaryLabel" class="jc__salary">{{ salaryLabel }}</p>
    </div>
  </div>
</template>

<script>
import CompanyMixin from '../../../mixins/company'
import EmploymentType from '../../employmentType'
import JobCardText from './jobCardText'
import JobCompanyLogo from '../jobCompany/jobCompanyLogo'
import JobMixin from '../../../mixins/job'
import TrackingJobMixin from '../../../mixins/tracking/job'

export default {
  components: {
    'emp-type': EmploymentType,
    'jc-logo': JobCompanyLogo,
    'jc-text': JobCardText,
  },
  computed: {
    jobSlug() {
      if (this.job && this.job.slug) {
        const slug = this.job.slug.find((s) => s.locale === this.currentLocale.code)
        if (slug && slug.value) {
          return slug.value
        }
      }
      return ''
    },
    salaryLabel() {
      if (this.hourlySalaryRange) {
        return `$${this.hourlySalaryRange}/${this.$t('general.hour')}`
      } else if (this.monthlySalaryRange) {
        return `$${this.monthlySalaryRange}/${this.$t('general.month')}`
      }
      return ''
    },
  },
  methods: {
    async onClick() {
      if (this.click) {
        await this.click()
      }
      this.$router.push({
        path: this.localePathName(`/job/${this.jobSlug}/${this.job._id}`),
      })
    },
    onImpressionEvent() {
      this.$emit('impression')
    },
  },
  mixins: [CompanyMixin, JobMixin, TrackingJobMixin],
  props: {
    /**
     * Click event function
     * REMARK: Make sure to finish click event before route change. So, instead of using $emit('click')
     */
    click: {
      default: null,
      type: Function,
    },
  },
}
</script>

<style lang="postcss" scoped>
.jc {
  @apply relative w-full max-w-card pt-3 px-3 pb-6/4 rounded-xl bg-white shadow border border-grey-200;
}
.jc__link {
  @apply absolute top-0 left-0 w-full h-full rounded-xl text-transparent bg-grey opacity-0 z-1;
  transition: opacity 0.2s ease;

  &:active {
    @apply opacity-10;
  }
}
.jc__content {
  @apply relative z-0;
}

.jc__tags {
  @apply mb-2 text-xs;

  & >>> .emp-type {
    @apply text-xs;
  }
}
.jc__cover {
  @apply flex flex-no-wrap w-full;

  & >>> .jc-logo {
    @apply ml-3;
    width: 60px;
    height: 60px;
  }
}
.jc__salary {
  @apply text-17/4 font-bold text-right;
}
</style>
