import { getTrackingSearchParams, sendJobAdsRequestEvent } from '../../libs/tracking/utils/event'
import { createSessionId } from '../../libs/tracking/utils/session'
import { findEmploymentTypeFieldByName } from '../../libs/mapping/employmentType'
import { GET_ADS_JOBS_BY_IDS_QUERY } from '../../api/octo/graphql/queries'
import { isLatLngValid } from '../../libs/location'
import { postSeekerGQL } from '../../api/octo/graphql'
import { requestAd } from '../../api/oppa/bidding'
import { sliceByInt } from '../../libs/data/transform'
import { v4 as uuidv4 } from 'uuid'

/**
 * Mixin for bidding utilities
 */
export default {
  data() {
    return {
      biddingAdIds: [],
      biddingAdJobs: [],
      biddingAdsPages: [],
      /**
       * Configuration for ads distribution
       */
      biddingAdsPattern: [2, 3, 3, 3],
      biddingSession: '',
      lastBiddingId: null,
      /**
       * Configuration for number of jobs between ads
       */
      requestId: null,
    }
  },
  methods: {
    async loadAds(eventSource = '', filterQueryObject, skipJobIds = []) {
      const employmentType = filterQueryObject.employment
      const jobTypes = filterQueryObject.jobType
      const districts = filterQueryObject.district
      const latlng = isLatLngValid(filterQueryObject.latitude, filterQueryObject.longitude)
        ? `${filterQueryObject.latitude},${filterQueryObject.longitude}`
        : ''
      const query = filterQueryObject.q
      if (employmentType || jobTypes.length || districts.length || latlng || query) {
        const res = await this.requestBiddingAds(employmentType, jobTypes, districts, latlng, skipJobIds, query)
        if (res && res.data && Array.isArray(res.data.ads) && res.data.ads.length > 0) {
          this.biddingAdIds = res.data.ads

          const ads = await this.requestAdJobs()
          this.biddingSession = createSessionId()
          this.lastBiddingId = res.data.bidid
          this.biddingAdJobs = ads
          this.biddingAdsPages = sliceByInt(this.biddingAdJobs, this.biddingAdsPattern)

          sendJobAdsRequestEvent(this.$eventTracker, {
            sessionId: this.biddingSession,
            source: eventSource,
            trackingSearchParams: getTrackingSearchParams(filterQueryObject),
          })
          return
        }
      }

      // Fail to load ads. Reset ad request params
      this.biddingAdJobs = []
      this.biddingAdIds = []
      this.biddingSession = ''
      this.biddingAdsPages = []
      this.lastBiddingId = null
    },
    async loadAdsForFeatureList() {
      const res = await this.requestBiddingAds()
      if (res && res.data && Array.isArray(res.data.ads) && res.data.ads.length > 0) {
        this.biddingAdIds = res.data.ads
        const ads = await this.requestAdJobs()
        this.biddingSession = createSessionId()
        this.lastBiddingId = res.data.bidid
        this.biddingAdJobs = ads
        this.biddingAdsPages = sliceByInt(this.biddingAdJobs, this.biddingAdsPattern)

        sendJobAdsRequestEvent(this.$eventTracker, {
          sessionId: this.biddingSession,
          source: 'feature_list',
        })
      }
    },
    async loadAdsForHomepageRecommendedJobs() {
      const res = await this.requestBiddingAds()
      if (res && res.data && Array.isArray(res.data.ads) && res.data.ads.length > 0) {
        this.biddingAdIds = res.data.ads
        const ads = await this.requestAdJobs()
        this.biddingSession = createSessionId()
        this.lastBiddingId = res.data.bidid
        this.biddingAdJobs = ads

        sendJobAdsRequestEvent(this.$eventTracker, {
          sessionId: this.biddingSession,
          source: 'homepage',
        })
      }
    },
    async requestAdJobs() {
      const variables = {
        ids: this.biddingAdIds,
      }
      const token = this.$store.state.auth.token
      // Remarks: GraphQL structure not support parallel to fire graphql request
      const res = await postSeekerGQL(GET_ADS_JOBS_BY_IDS_QUERY, variables, token)
      if (res && res.data && res.data.get_jobs && res.data.get_jobs.length) {
        // Add Ad field to identify ads
        res.data.get_jobs.forEach((jobAd) => {
          jobAd.is_ad = true
        })
        return res.data.get_jobs
      }
      return []
    },
    async requestBiddingAds(
      employmentTypes = [],
      jobTypes = [],
      districts = [],
      latlng = '',
      skipJobIds = [],
      query = '',
    ) {
      this.requestId = uuidv4()
      const res = await requestAd(
        this.requestId,
        employmentTypes.map((et) => findEmploymentTypeFieldByName(et, 'shortCode')).filter((sc) => sc),
        jobTypes.filter((sc) => sc),
        districts.filter((sc) => sc),
        latlng,
        skipJobIds,
        query,
        this.$store.getters['auth/userId'],
      )
      if (res && res.data) {
        return res
      }
      return null
    },
  },
}
