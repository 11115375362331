<template>
  <div :id="company.company_id" class="company-card">
    <!-- Overlaying job link -->
    <!--  REMARK: event="" avoid built-in click behaviour to fire route change  -->
    <!--  Reference: https://stackoverflow.com/questions/59885664/nuxt-how-to-prevent-nuxt-link-goes-to-another-page  -->
    <!--  Router Link source code: https://github.com/vuejs/vue-router/blob/6ec0ee563898ed513556f589209e8456d54ccd3b/src/components/link.js  -->
    <n-link
      class="jc__link"
      :title="company.name"
      event=""
      :to="localePathName(`/company/${encodeURIComponent(company.name)}/${company.company_id}`, currentLocale.code)"
      @click.native="onClick"
      >{{ company.name }}</n-link
    >
    <div class="company-card__content">
      <div class="company-card__img-wrap">
        <img
          v-if="company.company_logo_thumbnail"
          class="company-card__img"
          :src="company.company_logo_thumbnail"
          :alt="company.alt_text"
          loading="lazy"
          height="88"
          width="88"
        />
        <div v-else class="company-card__default-img">
          <div class="company-card__default-img-text-wrap">
            <p class="company-card__default-img-text company-card__default-img-text--zh">未有提供</p>
            <p class="company-card__default-img-text company-card__default-img-text--en">Not available</p>
          </div>
        </div>
      </div>
      <p>{{ company.name }}</p>
    </div>
  </div>
</template>

<script>
import { sendFeaturedCompanyClickEvent, sendFeaturedCompanyImpressionEvent } from '@/libs/tracking/utils/event'
import ImpressionListItemMixin from '../../../mixins/tracking/impressionListItem'

export default {
  methods: {
    onClick() {
      sendFeaturedCompanyClickEvent(this.$eventTracker, {
        company_id: this.company.company_id,
        position: this.position,
        source: this.source,
      })

      this.$router.push({
        path: this.localePathName(`/company/${encodeURIComponent(this.company.name)}/${this.company.company_id}`),
      })
    },
    onImpressionEvent() {
      if (this.isFireTrackingEvent) {
        sendFeaturedCompanyImpressionEvent(this.$eventTracker, {
          company_id: this.company.company_id,
          position: this.position,
          source: this.source,
        })
      }
    },
  },
  mixins: [ImpressionListItemMixin],
  props: {
    company: {
      required: true,
      type: Object,
    },
    position: {
      required: true,
      type: Number,
    },
    source: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.company-card {
  @apply relative w-full max-w-card px-3 pt-8 pb-7 rounded-xl bg-white h-full box-border;
}
.jc__link {
  @apply absolute top-0 left-0 w-full h-full rounded-xl text-transparent bg-grey opacity-0 z-1;
  transition: opacity 0.2s ease;

  &:active {
    @apply opacity-10;
  }
}
.company-card__content {
  @apply relative z-0 px-4 text-center;
}
.company-card__img-wrap {
  @apply flex items-center justify-center h-full;
}
.company-card__img {
  @apply rounded-xl;
}
.company-card__default-img {
  height: 88px;
  @apply rounded-xl bg-grey-xlight flex items-center;
}
.company-card__default-img-text-wrap {
  @apply p-3 text-center;

  & .company-card__default-img-text {
    @apply pt-0 whitespace-no-wrap;
  }
}
.company-card__default-img-text--en {
  font-size: 10px;
}
.company-card__default-img-text--zh {
  @apply text-xs;
}
.company-card__content p {
  @apply pt-2;
}
@screen mobile {
  .company-card {
    @apply mx-auto;
  }
}
</style>
