/**
 * Centralised configuration for
 * pages w/ localised page paths.
 * Full paths are required.
 */
export const pageLocales = {
  'employer/bidding': {
    en: '/en/employer/job-ad-booster/',
    'zh-hant': '/employer/提升曝光功能/',
  },
  'employer/blogs/list': {
    en: '/en/employer/blogs-list/recruitment-advice/',
    'zh-hant': '/employer/blogs-list/僱主網誌/',
  },
  'employer/community-guidelines': {
    en: '/en/employer/community-guidelines/',
    'zh-hant': '/employer/社群守則/',
  },
  'employer/faq': {
    en: '/en/employer/faq/',
    'zh-hant': '/employer/常見問題/',
  },
  'employer/landing': {
    en: '/en/employer/recruitment-services/',
    'zh-hant': '/employer/recruitment-services/',
  },
  'jobseeker/app': {
    en: '/en/jobseeker/mobile-app/',
    'zh-hant': '/jobseeker/手機app/',
  },
  'jobseeker/blogs/list': {
    en: '/en/blogs-list/career-blogs/',
    'zh-hant': '/blogs-list/職場網誌/',
  },
}
