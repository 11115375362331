<template>
  <section class="ftr-top ftr__section">
    <app-footer-follow-us />
    <div class="ftr-space" />
    <app-footer-section :title="$t('link.aboutMoovup')" :links="aboutUsSectionLinks" />
    <app-footer-section :title="$t('footer.seeker')" :links="jobSeekerSectionLinks" @click="onClick" />
    <app-footer-section :title="$t('footer.employer')" :links="employerSectionLinks" @click="onClick" />
  </section>
</template>

<script>
import AppFooterFollowUs from './appFooterFollowUs'
import AppFooterSection from './appFooterSection'
import { getMailJetPageURL } from '../../locales/mailjetPages'
import { getTrackingURL } from '../../libs/tracking/utils/url'
import MenuMixin from '../../mixins/menu/section'
import { sendEmployerPortalClickEvent } from '../../libs/tracking/utils/event'

export default {
  components: {
    'app-footer-follow-us': AppFooterFollowUs,
    'app-footer-section': AppFooterSection,
  },
  computed: {
    aboutUsSectionLinks() {
      return [
        {
          href: getMailJetPageURL('jobseeker/app', this.currentLocale.code),
          title: this.$t('link.aboutUs'),
        },
        {
          href: getMailJetPageURL('contact-us', this.currentLocale.code),
          title: this.$t('link.contact'),
        },
        {
          href: getMailJetPageURL('join-our-team', this.currentLocale.code),
          title: this.$t('link.joinOurTeam'),
        },
        {
          linkPath: this.localePathName('/terms-and-conditions'),
          title: this.$t('link.tnc'),
        },
        {
          linkPath: this.localePathName('/privacy-policy'),
          title: this.$t('link.privacyPolicy'),
        },
      ]
    },
    employerSectionLinks() {
      return [
        {
          linkPath: this.getArticleLocalePath('employer/landing'),
          title: this.$t('link.ourService'),
        },
        {
          event: {
            name: 'portal',
          },
          href: getTrackingURL('https://employer.moovup.com/', 'referral', this.referralSource, 'footer'),
          title: this.$t('link.recruitmentPlatform'),
        },
        {
          // Always link to chinese employer blogs list page
          linkPath: this.getArticleLocalePath('employer/blogs/list', this.$i18n.defaultLocale),
          title: this.$t('link.recruitmentAdvice'),
        },
        {
          href: getMailJetPageURL('employer/faq', this.currentLocale.code),
          title: this.$t('link.employerFAQ'),
        },
        {
          href: getMailJetPageURL('employer/community-guidelines', this.currentLocale.code),
          title: this.$t('link.communityGuideline'),
        },
        {
          href: this.getOdsIntroURL(this.currentLocale.code),
          title: this.$t('link.ods'),
        },
      ]
    },
    jobSeekerSectionLinks() {
      return [
        {
          linkPath: this.localePathName('/'),
          title: this.$t('link.jobSearch'),
        },
        {
          event: {
            name: 'my_profile',
          },
          title: this.$t('link.myResume'),
        },
        {
          event: {
            name: 'my_job_alert',
          },
          title: this.$t('link.jobAlert'),
        },
        {
          // Always link to chinese seeker blogs list page
          linkPath: this.getArticleLocalePath('jobseeker/blogs/list', this.$i18n.defaultLocale),
          title: this.$t('link.careerBlogs'),
        },
      ]
    },
  },
  methods: {
    getOdsIntroURL(locale) {
      const links = {
        en: 'https://ods-employer-intro-en-cta.moovup.com/',
        'zh-hant': 'https://ods-employer-intro-zh-cta.moovup.com/',
      }
      return links[locale]
    },
    onClick(link) {
      const event = link.event
      if (event) {
        switch (event.name) {
          case 'portal': {
            sendEmployerPortalClickEvent(this.$eventTracker)
            break
          }
          default:
            this.clickAccountSections(event.name)
            break
        }
      }
    },
  },
  mixins: [MenuMixin],
  props: {
    /**
     * Source for tracking in referral links
     */
    referralSource: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.ftr-space {
  @apply flex-grow min-w-0;
}

@screen desktop {
  .app-footer-section {
    margin-left: 56px;
  }
}
</style>
