<template>
  <b-icon-text
    class="b-nav"
    :class="buttonClasses"
    :href="href"
    :link-path="linkPath"
    :target="target"
    :title="title"
    @click="onClick"
  >
    <!-- New label -->
    <template #icon>
      <span v-if="isNew" class="b__new">{{ $t('general.new') }}</span>
    </template>
  </b-icon-text>
</template>

<script>
import { formatArticlesListPath } from '../../libs/article/list'
import IconTextButton from './iconTextButton'

export default {
  components: {
    'b-icon-text': IconTextButton,
  },
  computed: {
    buttonClasses() {
      return {
        // Handle the page number param in blogs list page path
        'b--link-current': this.linkPath === formatArticlesListPath(this.$route.path, 1),
      }
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
  props: {
    /**
     * External path for button-like link
     */
    href: {
      default: '',
      type: String,
    },
    /**
     * Whether button item is new
     */
    isNew: {
      default: false,
      type: Boolean,
    },

    /**
     * Route path for button-like link
     */
    linkPath: {
      default: '',
      type: String,
    },

    /**
     * External link target
     */
    target: {
      default: '_blank',
      type: [String, Boolean],
    },

    /**
     * Button title
     */
    title: {
      required: true,
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.b.b-icon_text.b-nav {
  @apply flex-row-reverse justify-end;
  @apply px-5 bg-transparent shadow-none;

  & .b__new {
    @apply block ml-2 px-1 text-white bg-pink;
    @apply text-coverline font-coverline;
    line-height: 22px;
    border-radius: 6px 6px 6px 0;
  }
  &::v-deep .b__title {
    @apply text-cbody1 font-cbody1;
  }

  &:not(.b--link-current) {
    @apply text-black;
  }
  /* Link points to current page */
  &.b--link-current {
    @apply text-red-500 pointer-events-none;
  }
}
</style>
