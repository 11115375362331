import errorKeys from '../libs/errorKeys'

export default {
  account: {
    restore: {
      actionMain: '恢復帳戶',
      actionOptional: '立即登出',
      backToHome: '回到主頁',
      restoreDesc:
        '抱歉！你的帳戶正在刪除中，你無法使用現有帳戶瀏覽工作。請先登出你的帳戶，以訪客身份繼續瀏覽工作。\n\n請注意：系統將於{dateToBeDeleted}永久刪除你的 Moovup 帳戶，這也會刪除所有與該帳戶相關聯的資料，包括個人資料、電話號碼、帳戶頭像等。在等待移除帳戶期間﹐你的帳戶將不能使用，但帳戶仍然存在。如果你要恢復Moovup 帳戶，只要在90天內再次登入並確認恢復即可。',
      restoreTitle: '帳戶刪除中',
      successDesc: '感謝你繼續使用Moovup好工速遞，你需要重新登入以使用原先的帳戶申請工作。',
      successTitle: '成功恢復帳戶',
    },
  },
  allowance: {
    meal: '膳食',
    other: '其它',
    travel: '交通',
  },
  appInstall: {
    rejectedContent: '下載手機應用程式，\n隨時緊貼申請狀況',
  },
  application: {
    appliedAt: '申請於',
    detailTitle: '申請詳情',
    myApplicationAppliedLocation: '已申請地點',
    myApplications: '我的申請',
    myApplicationStatus: '狀態',
    myApplicationStatusApplied: '已提交申請',
    myApplicationStatusContacted: '僱主有機會以電話和你聯絡',
    myApplicationStatusJobClosed: '此職位暫停招聘，如短期內未獲聯絡，可考慮申請其他工作',
    myApplicationStatusRejected: '抱歉，你的申請未被接納',
    myApplicationStatusReviewed: '你的申請已被查閱',
  },
  applicationStatus: {
    All: '全部',
    Rejected: '已拒絕',
    Reviewed: '已查閱',
  },
  apply: {
    applied: '已申請',
    appliedLocationTitle: '申請地點：',
    apply: '申請',
    applyJobDesFormat: '你正在申請 {companyName} 的職位 {jobTitle}',
    applyJobSuccessFormat: '你已成功申請 {companyName} 的職位 {jobTitle}',
    chooseLocation: '選擇工作地點',
    expired: '已失效',
    fillCV: '填寫履歷',
    selectedLocations: '已選擇的工作地點',
    selectLocationFormat: '已選: {selectedNum}/{totalNum}',
    success: '申請成功',
    title: '申請工作',
    viewApplication: '查閱該申請',
    viewOtherJob: '繼續瀏覽其他工作',
  },
  article: {
    readAll: '閱讀全文',
    updatedOn: '更新於',
  },
  articles: {
    nextPage: '下一頁',
    prevPage: '上一頁',
  },
  company: {
    emptyAboutCompany: '未有提供',
    featuredCompany: '精選僱主',
  },
  country: {
    hk: '香港 (中文)',
  },
  dayOfWeek: {
    0: '週一',
    1: '週二',
    2: '週三',
    3: '週四',
    4: '週五',
    5: '週六',
    6: '週日',
    multiple: '{start}至{end}',
    negotiable: '日子可商議',
  },
  district: {
    Aberdeen: '香港仔',
    Central: '中區',
    'DBay & Disney': '愉景灣 & 迪士尼',
    'Fanling & Sheung Shui': '粉嶺 & 上水',
    'Hong Kong Island': '香港島',
    Hunghom: '紅磡',
    'Island East': '港島東',
    'Island South': '港島南',
    'Island West': '港島西',
    Kowloon: '九龍',
    'Kowloon Bay': '九龍灣',
    'Kowloon City': '九龍城',
    'Kowloon Tong': '九龍塘',
    'Kwai Chung': '葵涌',
    'Kwun Tong': '觀塘',
    label: '地區',
    Lantau: '北大嶼山',
    'Ma On Shan': '馬鞍山',
    'Mei Foo': '美孚',
    'Mid-Levels & Peak': '半山 / 山頂',
    'New Territories East': '新界東',
    'New Territories West': '新界西',
    'Pok Fu Lam': '薄扶林',
    'Sai Kung': '西貢',
    'Sham Shui Po': '深水埗',
    Shatin: '沙田',
    'Tai Po': '大埔',
    'Tin Shui Wai': '天水圍',
    'Tseung Kwan O': '將軍澳',
    'Tsing Yi': '青衣',
    'Tsuen Wan': '荃灣',
    'Tuen Mun': '屯門',
    'Tung Chung and Airport': '東涌 & 機場',
    'Wan Chai / Causeway Bay': '灣仔 / 銅鑼灣',
    'West Kowloon': '西九龍',
    'Wong Tai Sin': '黃大仙',
    'Yau Tsim Mong': '油尖旺',
    'Yuen Long': '元朗',
  },
  education: {
    'Associate Degree': '副學士',
    'Diploma / Certificate': '文憑 / 證書',
    'ERB / Other Training Certificate': '僱員再培訓局 或 其他培訓證書',
    'Form 3': '中三',
    'Higher Diploma / Certificate': '高級文憑 / 證書',
    'HKDSE or equivalent': '中學文憑 / 同級',
    postLevel: '大專 / 大學',
    'Primary Education': '小學',
    primaryLevel: '小學教育',
    secondaryLevel: '中學教育',
    'Tertiary Education': '大學',
    'Vocational Education': 'VTC文憑',
  },
  employer: {
    bidding: '提升曝光功能',
    faq: '常見問題',
    homepage: '主頁',
    login: '僱主登入',
    recruitmentAdvice: '僱主網誌',
    recruitmentSolutions: '招聘方案',
    temp: '臨時工配對服務',
  },
  employerSellingPage: {
    appDownloads: '應用程式下載量',
    completelyFree: '100%免費',
    contactAndHireRightCandidate: '聯絡並聘用合適人員',
    contactCandidatesAndTakeQuickActions: '通過WhatsApp或電話迅速聯絡求職者、並完成招聘。',
    downloadMoovupApp: '下載應用程式',
    easyToHire: '輕鬆招聘',
    employerLogin: '僱主登入',
    exposeYourJobCardApplicant1: '美鈴',
    exposeYourJobCardApplicant2: '黃志明',
    exposeYourJobCardApplicant3: 'Ravi',
    exposeYourJobCardAppliedJob: '申請了：咖啡師',
    exposeYourJobCardContact: '聯絡方式',
    exposeYourJobPost: '大量前線求職者看到您的招聘廣告',
    faq: '常見問題',
    faqContent1a: 'Moovup 好工速遞是專為前線行業而設的招聘平台，以AI及機器學習程式為求職者配對僱主招聘廣告。',
    faqContent1b: '平台集齊全港不同前線空缺，陪伴求職者尋找下個可能，讓工薪階層能夠在生活中有所提升。',
    faqContent2a: '100%免費，沒有任何隱藏費用！',
    faqContent2b: '您可以免費刊登職位並開始招聘。如您需要，我們另有額外的付費功能。',
    faqContent3: 'Moovup 好工速遞覆蓋16個行業：',
    faqContent4a:
      '任何持有有效香港商業登記證的公司均可登記成為僱主，並選擇以手機號碼或電郵進行註冊（如以電郵註冊，請見以下其它常見問題關於電郵的要求）。',
    faqContent4b:
      '如果是非牟利組織而未能上傳香港商業登記證，可以上傳由政府發出關於税務條例第88條下豁免繳税的確認文件。',
    faqContent5a: '簡單3步即可聘請人才：',
    faqContent5b: '第1步：登記及免費刊登空缺',
    faqContent5c: '第2步：查看及篩選合適的求職者',
    faqContent5d: '第3步：通過WhatsApp或電話迅速聯絡求職者、並完成招聘',
    faqContent6a: '有的，Moovup 好工速遞提供以下額外付費功能：',
    faqContent6b:
      '增加曝光功能（Job Ad Booster）：您的職位將在 Moovup 好工速遞網站和手機應用程式的顯著位置展示。透過增加職位曝光和點擊率，這有助於吸引更多的申請者。費用是基於點擊次數計算的，沒有點擊就沒有費用。{0}有關增加曝光功能的詳細資訊。',
    faqContent6bLearnMore: '了解更多',
    faqContent6c:
      '橫幅廣告（Ad Banner）：僱主可以透過 Moovup 好工速遞網頁及應用程式的首頁橫額廣告，吸引更多的求職者。如需了解更多，請聯繫 {0}。',
    faqContent6Email: 'cs@moovup.com',
    faqContent7:
      '我們使用亞馬遜網絡服務（Amazon Web Services）來儲存我們用戶的數據。亞馬遜網絡服務是全球最受推崇和安全的雲計算平台之一。如需了解更多有關亞馬遜網絡服務，請瀏覽 {0}。',
    faqContent7Email: 'https://aws.amazon.com',
    faqContent8a: '請見下列僱主常見問題全文以了解更多。',
    faqContent8b: '您也可以聯繫cs@moovup.com，我們的團隊很樂意回答您的任何問題。',
    faqTitle1: 'Moovup 好工速遞是什麼？',
    faqTitle2: '刊登職位空缺的費用是多少？',
    faqTitle3: '平台覆蓋哪些行業？',
    faqTitle4: '在 Moovup 好工速遞登記成為僱主有甚麼要求？',
    faqTitle5: 'Moovup 好工速遞如何運作？',
    faqTitle6: 'Moovup 好工速遞是否有額外的付費功能？',
    faqTitle7: '數據存儲的保安措施有多可靠？',
    faqTitle8: '如果我有進一步的問題該怎麼辦？',
    frontLineJobPlatform: '前線招聘平台',
    frontLineWorker: '前線員工',
    hiring: '開始招聘',
    hour: '小時',
    jobAdBooster: '提升曝光功能',
    jobDuty: '工作職責',
    jobSeeker: '求職者平台',
    metaTitle: '免費刊登招聘廣告 | 前線員工及工作 | Moovup 好工速遞',
    monthlyActiveSeekers: '每月活躍前線求職者',
    monthlyActiveSeekersNumber: '25萬',
    noCost: '零成本',
    noHiddenFees: '無隱藏費用',
    oneMillion: '100萬',
    postAJobForFree: '免費刊登空缺',
    postJobAndLetSeekerKnowVacancies: '只需幾分鐘即可刊登招聘，讓數以千計前線求職者知道您的空缺。',
    postJobForFreeAtAnytime: '隨時隨地，免費發佈招聘廣告',
    postJobForFreeCardCompany: 'Moovup 咖啡廳',
    postJobForFreeCardDaysOrWeeks: '每週工作 {0} 天',
    postJobForFreeCardJobDuty:
      '我們正在尋找經驗豐富的咖啡師加入我們不斷發展的團隊。這是一個適合希望成為領導角色的人的激動人心的職位。',
    postJobForFreeCardJobTitle: '咖啡師',
    postJobForFreeCardLocation: '中環',
    postJobForFreeCardWorkingTime: '60-100',
    readFAQ: '查看所有僱主常見問題',
    receiveJobApplication: '高效接收求職申請，查看及篩選合適的求職者。',
    registerWithPhoneOrEmail: '無論身在何地，均可用手機或電腦、以手機號碼或電郵進行註冊及管理招聘廣告。',
    startHiring: '開始招聘',
    startHiringNow: '立即開始招聘',
    step1: '第一步',
    step2: '第二步',
    step3: '第三步',
    testimonyAuthor1: 'Paul Hotchan',
    testimonyAuthor1Title: '人才招聘部主管',
    testimonyAuthor2: 'Alina Chow',
    testimonyAuthor2Title: 'Senior Talent Acquisition Manager',
    testimonyAuthor3: 'Kan',
    testimonyAuthor3Title: '餐廳大廚',
    testimonyCompany1: '香港賽馬會',
    testimonyCompany2: 'DFI Retail Group',
    testimonyCompany3: 'Gotthard Base',
    testimonyText1:
      'Moovup 好工速遞的創新方法、廣大的求職者用戶群、以及接觸前線求職者的能力，對我們在香港賽馬會的招聘工作起了非常重大的作用。此外，他們的活動創造了一種由線上到線下的求職體驗，吸引人之外，也令我們希望招聘的新一代員工產生共鳴。我們期待繼續我們成功的夥伴關係。',
    testimonyText2:
      'Moovup 好工速遞提供了一站式的招聘服務，令顧主能有效地接觸到更多潛在求職者。簡易的平台使求職者能輕鬆搜尋及申請我們的職位，大大提升他們的應徵體驗。',
    testimonyText3:
      '我代我朋友多謝你，佢原本係將軍澳美食廣場長時間都找不到人手，但經過係你Moovup網頁刊登後就找到適合人選，而且仲有好多人見工，令佢既人生有番希望，另外原來我哋中菜果邊都係在Moovup中成功找到洗碗姐姐，唔該晒，繼續努力！',
    titleFaq: '{0}',
    titleFaq2: '常見問題',
    titleFocusFrontLine: '我們專注服務{0}',
    titleFocusFrontLine2: '前線行業',
    titleHire: '{0}3步即可招聘',
    titleHire2: '簡單',
    titleTestimonies: '{0}分享',
    titleTestimonies2: '僱主',
    titleTrustedBy: '超過{0}間公司登記',
    titleTrustedBy2: '5萬',
    vacancies: '職位空缺',
  },
  employment: {
    FullTime: '全職',
    PartTime: '兼職',
    Temp: '臨時工',
  },
  error: {
    [errorKeys.APPLY_LOC_MAX]: '您只能選擇5個位置。',
    [errorKeys.CONFIRM_SIGNUP_FAILED]: '我們未能成功註冊你的帳號，請稍後再試。',
    [errorKeys.E100005]: '遞交申請失敗。請稍候再試。',
    [errorKeys.E100026]: '公司不存在',
    [errorKeys.E100027]: '你沒有存取權限',
    [errorKeys.E100056]: '你的申請數量已達每日/每月上限，請稍後再試。',
    [errorKeys.EMAIL_INVALID]: '電郵不正確',
    [errorKeys.EMAIL_REGISTERED]: '電郵已被登記',
    [errorKeys.EMAIL_RESENT]: '已重新發送驗證電郵',
    [errorKeys.FILE_NOT_SELECTED]: '請選取文件',
    [errorKeys.FILE_SIZE_EXCEED_LIMIT]: '文件大小超過上限',
    [errorKeys.FILE_TYPE_NOT_ACCEPTED]: '文件格式不正確',
    [errorKeys.LIST_COUNT_BELOW_ONE]: '請選取至少1個選項',
    [errorKeys.LOGIN_FAILED]: '你所輸入的電郵/密碼不正確',
    [errorKeys.LOGIN_OTP_INVALID]: '驗證碼無效，請重試',
    [errorKeys.PASSWORD_ALREADY_SET]: '你已設定密碼，請嘗試登入。',
    [errorKeys.PASSWORD_INVALID]: '密碼不符合格式 (至少6位密碼包括英文大小階及數字)',
    [errorKeys.PASSWORDS_NOT_MATCH]: '密碼不一致',
    [errorKeys.REQUEST_ERROR]: '網絡發生錯誤，請稍後再試。',
    [errorKeys.REQUIRED]: '必須填寫',
    [errorKeys.SET_PASSWORD_FAILED]: '我們未能成功設定你的密碼，請稍後再試。',
    [errorKeys.TELEPHONE_INVALID]: '電話號碼不正確 (至少8位數字)',
    [errorKeys.TOKEN_EXPIRED]: '登入已過期',
    [errorKeys.UPLOAD_FAILED]: '我們未能成功上載文件，請稍後再試。',
    content404: '對不起！你輸入了錯誤的網址或尋找的網頁不存在。',
    home: '回到主頁',
    title404: '網頁不存在',
  },
  footer: {
    allRights: '保留一切權利',
    copyright: '版權所有',
    employer: '僱主',
    followUs: '追蹤我們',
    seeker: '求職者',
  },
  general: {
    add: '新增',
    allCategory: '所有職位類別',
    back: '返回',
    cancel: '取消',
    close: '結束',
    colon: '：',
    comma: '，',
    confirm: '確認',
    continue: '繼續',
    delete: '刪除',
    download: '下載',
    edit: '編輯',
    home: '主頁',
    hour: '小時',
    loadMore: '顯示更多',
    month: '月',
    more: '去看看',
    new: '新',
    next: '下一步',
    noCompanyRecord: '找不到相配的僱主',
    noRecord: '找不到相配的工作',
    notAvailable: '不適用',
    ok: '明白',
    or: '或',
    quitNow: '離開',
    readMore: '查看更多',
    removeProfilePicture: '移除照片',
    reset: '重置',
    save: '儲存',
    select: '請選擇',
    selected: '已選擇',
    uploadNow: '上載相片',
  },
  header: {
    accountTitle: '帳號',
  },
  homepage: {
    link: 'Moovup 主頁',
    metaDescription:
      '香港免費前線招聘求職平台，招聘兼職part-time、全職、臨時工、包裝員、文員、倉務員超過{numberOfJobs}份前線職位空缺。餐飲、零售、物流、運輸、包裝生產、活動推廣及客戶服務等行業正在招聘的工作，更可建立你的網上電子履歷表及求職喜好，方便職位配對及接收最新職缺通知。搵工網、搵工app同時適用，立即開始於Moovup好工速遞搵工。',
    recList: '搶手好工',
  },
  input: {
    change: '更換',
    companyName: '品牌 / 顯示名稱',
    contactPerson: '姓名',
    email: '電郵',
    fileFormats: '請上載 {formats} 格式的文件。',
    fileSize: '文件不能超過 10 MB。',
    jobType: '職位類別',
    legalName: '公司註冊名稱',
    password: '密碼',
    passwordConfirm: '確認密碼',
    telephone: '電話號碼',
    upload: '上載',
  },
  jobAlert: {
    createAlertContent: '下載手機應用程式，隨時緊貼最新好工',
    createTitle: '有符合條件的新空缺時通知我',
    subscribe: '訂閱',
    title: '我的空缺通知',
  },
  jobCategory: {
    'Beauty & Fitness': '美容及健身服務',
    Cleaning: '清潔',
    Construction: '建造業',
    'Customer Services': '客戶服務',
    Education: '教育',
    'Events & Promotion': '活動和推廣',
    'Food & Beverage': '餐飲業',
    'Health Services': '醫療保健服務',
    'Hotel & Club House': '酒店及會所',
    'Logistics & Transport': '物流及運輸',
    Maintenance: '維修',
    nearby: '附近工作',
    Office: '辦公室職位',
    Others: '其他',
    'Production & Packaging': '生產及包裝',
    'Property Mgt & Security': '保安及物業管理',
    'Retail Shop': '零售門市',
    'Sales / Agents': '推銷 / 中介',
  },
  jobDetail: {
    allowance: '津貼和福利',
    careerLevelEntry: '入門階層',
    careerLevelMiddle: '中級階層',
    careerLevelSenior: '經理階層',
    careerLevelTop: '高級管理層',
    cert: '證書及許可證:',
    companyAbout: '僱主資料',
    companyMoreJobs: '僱主其他空缺',
    daysPerWeek: '每星期 {days} 日',
    daysPerWeekFull: '每星期 {days} 日',
    description:
      '{employerName}請人，招聘{employmentType}{jobTitle}，人工{salary}，於{district}區工作；{workingDays}{workingHours}，立即開始於Moovup好工速遞申請或搜尋其他{jobType}的職位空缺。',
    downloadApp: '下載應用程式進行申請',
    education: '教育程度:',
    emptyAboutCompany: '未有提供',
    endDate: '結束日期',
    hoursPerDay: '每天 {hours} 小時',
    hoursPerDayFull: '每天 {hours} 小時',
    jobAdsLabel: '推廣',
    jobDuty: '工作職責',
    languageBasic: '基本',
    languageExcellent: '優良',
    languages: '語言:',
    location: '工作地點',
    mapLink: '在 Google Maps 中查看工作地點',
    // eslint-disable-next-line no-template-curly-in-string
    metaSalaryPerHour: '${number}/小時',
    // eslint-disable-next-line no-template-curly-in-string
    metaSalaryPerMonth: '${number}/月',
    postedAMonthAgo: '1個月前',
    postedAWeekAgo: '7日前',
    postedJustNow: '剛剛發佈',
    postedMoreThanThreeMonthsAgo: '3個月前',
    postedThisWeek: '本週發佈',
    postedThreeMonthsAgo: '3個月前',
    postedTwoMonthsAgo: '2個月前',
    relatedJobs: '類似工作',
    requirement: '工作要求',
    rotationShift: '需輪班工作',
    saturdayRequired: '長短週',
    showMore: '顯示全部',
    skill: '技能:',
    spokenSkills: '口語',
    startDate: '開始日期',
    timetable: '工作時間',
    title:
      '{employmentType}{jobTitle}招聘，{district}{employmentType}{jobTitle}工作{employerName}招聘 | Moovup 好工速遞',
    vacancy: '{n} 空缺',
    writtenSkills: '書寫',
  },
  jobRelated: {
    company: '僱主其他空缺',
    related: '類似工作',
  },
  jobType: {
    'Beauty & Fitness': {
      'Beauty Consultant': '美容顧問',
      Cosmetologist: '美容師',
      'Fitness Trainer': '健身教練',
      'Makeup Artist': '化妝師',
      Manicurist: '美甲師',
      Masseur: '按摩師',
      'Other Beauty & Fitness Jobs': '其它美容、健身工作',
    },
    Cleaning: {
      Airport: '機場',
      Hospitals: '醫院',
      'Hotels and Club Houses': '酒店、會所',
      'Office Buildings': '辦公大樓',
      'Other Cleaning Jobs': '其它清潔工作',
      'Other Premises': '其它場地',
      'Public Areas': '公共場所',
      'Residential Buildings': '住宅大廈',
      Restaurants: '食肆',
      Schools: '學校',
      'Shops and Malls': '商場、商鋪',
    },
    Construction: {
      'Bar Bending and Fixing': '鋼筋屈紮',
      'Bricklaying / Plastering / Tiling': '砌磚、批盪及鋪瓦',
      Carpentry: '木工',
      'Civil Engineering': '土木工程',
      Concreting: '混凝土',
      'Drainlaying / Piplaying': '地渠及喉管',
      'Electrical Work': '電',
      'Equipment Operation': '設備操作',
      'Metal Work & Welding': '金工及焊接',
      'Other Construction Jobs': '其它建造業工作',
      'Painting & Decoration': '髹漆及裝飾',
      Plumbing: '水喉',
      Scaffolding: '棚架',
      Surveying: '測量',
      'Works Supervisor': '監工',
    },
    'Customer Services': {
      'Customer Services': '客戶服務',
      'Tele Customer Services': '電話客戶服務',
    },
    Education: {
      'After-school Tutoring': '功課輔導班',
      'Arts & Crafts, Hobbies': '視藝、手工藝、興趣班',
      Chinese: '中文',
      English: '英文',
      'Exam Personnel': '考務人員',
      Kindergarten: '幼稚園',
      Maths: '數學',
      Music: '音樂',
      'Other Education Jobs': '其它教育工作',
      'Other Subjects': '其它學科',
      Science: '科學',
      Sports: '體育',
      STEM: 'STEM',
      'Teacher Assistant': '教師助理',
    },
    'Events & Promotion': {
      'Event Helper': '活動助理',
      Fundraiser: '籌款員',
      'Other Events & Promotion Jobs': '其它活動和推廣工作',
      'Promoter - Events': '推廣員 - 宣傳活動',
      'Promoter - In-store': '推廣員 - 店內',
      'Survey and Research': '市場調查',
    },
    'Food & Beverage': {
      Barista: '咖啡師',
      Bartender: '調酒師 / 酒保',
      'Chef / Assistant - Chinese Cuisine': '廚師 / 幫工 - 中餐',
      'Chef / Assistant - Dessert / Bakery': '廚師 / 幫工 - 甜品 / 糕點',
      'Chef / Assistant - Japanese & Korean': '廚師 / 幫工 - 日、韓',
      'Chef / Assistant - Others': '廚師 / 幫工 - 其它',
      'Chef / Assistant - Western Cuisine': '廚師 / 幫工 - 西餐',
      Dishwashing: '洗碗',
      'Drinks / Snacks Stalls': '外賣飲品、小食檔',
      'Drinks Counter': '茶水吧',
      'Fine Dining Chef / Assistant - Chinese Cuisine': '廚師 / 幫工 - 高級中餐',
      'Fine Dining Chef / Assistant - Dessert / Bakery': '廚師 / 幫工 - 高級甜品 / 糕點',
      'Fine Dining Chef / Assistant - Japanese & Korean': '廚師 / 幫工 - 高級日、韓',
      'Fine Dining Chef / Assistant - Other Cuisines': '廚師 / 幫工 - 其它高級食肆',
      'Fine Dining Chef / Assistant - Western Cuisine': '廚師 / 幫工 - 高級西餐',
      'Fine Dining Waiter & Attendant - Chinese Cuisine': '侍應、服務員 - 高級中餐',
      'Fine Dining Waiter & Attendant - Dessert / Bakery': '侍應、服務員 - 高級甜品 / 糕點',
      'Fine Dining Waiter & Attendant - Japanese & Korean': '侍應、服務員 - 高級日、韓',
      'Fine Dining Waiter & Attendant - Others': '侍應、服務員 - 其它高級食肆',
      'Fine Dining Waiter & Attendant - Western Cuisine': '侍應、服務員 - 高級西餐',
      'Other F&B Jobs': '其它餐飲業工作',
      'Subtitle - Chef / Assistant': '廚師 / 幫工',
      'Subtitle - Drinks': '飲品',
      'Subtitle - Fine Dining Chef / Assistant': '廚師 / 幫工 - 高級食肆',
      'Subtitle - Fine Dining Waiter & Attendant': '侍應、服務員 - 高級食肆',
      'Subtitle - Others': '其它',
      'Subtitle - Waiter & Attendant': '侍應、服務員',
      'Waiter & Attendant - Chinese Cuisine': '侍應、服務員 - 中餐',
      'Waiter & Attendant - Dessert': '侍應、服務員 - 甜品',
      'Waiter & Attendant - Others': '侍應、服務員 - 其它',
      'Waiter & Attendant - Western Cuisine': '侍應、服務員 - 西餐',
      'Waiter & Attendent - Japanese & Korean': '侍應、服務員 - 日、韓',
    },
    'Health Services': {
      'Care-related Support Worker': '醫護支援人員',
      'Clinic Assistant / Admin': '診所助理 / 行政',
      Midwife: '助產士',
      Nurse: '護士',
      'Nursing Assistant': '護理助理、陪診員',
      'Other Health Services Jobs': '其它醫療保健服務工作',
      'Postnatal Care': '陪月',
    },
    'Hotel & Club House': {
      Butler: '管房 / 客房服務員',
      'Clubhouse Assistant': '會所助理',
      Concierge: '禮賓服務 / 接待員',
      'Front Desk / Guest Relation': '前台 / 客戶關係',
      Housekeeping: '房務',
      'Life Guard': '救生員',
      'Linen Attendant': '布草房',
      'Other Hotel & Club House Jobs': '其它酒店及會所工作',
      'Recreation Attendant': '康體助理',
    },
    'Logistics & Transport': {
      'Airport / Shipping - Baggage Attendant': '機場 / 船運 - 行李服務員',
      'Airport / Shipping - Cargo Operation': '機場 / 船運 - 貨運',
      'Airport / Shipping - Driver': '機場 / 船運 - 司機',
      'Airport / Shipping - Machine Operator': '機場 / 船運 - 機器操作',
      Courier: '送貨員',
      'Courier - Driver': '送貨司機',
      'Food Delivery': '食品外賣',
      'Food Delivery - Driver': '食品外賣司機',
      'Logistics Assistant': '物流助理',
      'Other Logistics & Transport Jobs': '其它物流及運輸工作',
      'Private Driver': '私人司機',
      'Public Transportation - Driver': '公共交通 - 司機',
      'Public Transportation - Station Attendant': '公共交通 - 站務員',
      'Subtitle - Airport / Shipping': '機場 / 船運',
      'Subtitle - Others': '其它',
      'Subtitle - Public Transportation': '公共交通',
      'Valet Parking Attendant': '代客泊車',
      'Warehouse Assistant': '倉務員',
    },
    Maintenance: {
      'Air Conditioning': '冷氣技工',
      'Building Maintenance': '屋宇保養',
      Cars: '汽車',
      Drainage: '渠務',
      'Electrical Technician': '電機技工',
      Gardening: '花王、園藝',
      'Other Maintenance Jobs': '其它維修工作',
      'Pest Control': '滅蟲',
      'Plumbing / Electrician': '水、電技工',
      Renovation: '裝修',
    },
    Office: {
      Accounts: '會計文員',
      Clerk: '文員',
      'Data Entry': '資料輸入員',
      Design: '設計',
      'HR & Admin': '人事及行政',
      Intern: '實習生',
      Marketing: '市場推廣',
      Merchandiser: '跟單員',
      'Office Assistant': '辦公室助理',
      'Other Office Jobs': '其它辦公室工作',
      'Procurement - Food & Beverage': '採購 - 食品',
      'Procurement - Non-food': '採購 - 非食品',
      Receptionist: '接待員',
      Secretary: '秘書',
    },
    Others: {
      'Other Job': '其他',
    },
    'Production & Packaging': {
      'Food Packaging': '食品包裝',
      'Food Production': '食品生產',
      'Laundry Factory': '洗衣工廠',
      Manufacturing: '製造業',
      'Medicine Packaging': '藥物包裝',
      'Medicine Production': '藥物生產',
      Others: '其它生產及包裝工作',
    },
    'Property Mgt & Security': {
      Airport: '機場',
      'Hotels and Club Houses': '酒店、會所',
      'Office Buildings': '辦公大樓',
      'Other Premises': '其它場地',
      'Other Security Jobs': '其它保安工作',
      'Property Management': '物業管理',
      'Public Areas': '公共場所',
      'Residential Buildings': '住宅大廈',
      Schools: '學校',
      'Security Box Carrier': '押運員',
      'Security Car Driver': '押運車司機',
      'Shops and Malls': '商場、商鋪',
    },
    'Retail Shop': {
      Cashier: '收銀員',
      'Other Retail Jobs': '其它零售工作',
      'Shopkeeper - Clothing': '售貨員 - 服裝',
      'Shopkeeper - Electric Products': '售貨員 - 電器產品',
      'Shopkeeper - Food': '售貨員 - 食品',
      'Shopkeeper - Household Products': '售貨員 - 家庭用品',
      'Shopkeeper - Luxury': '售貨員 - 奢侈品',
      'Shopkeeper - Others': '售貨員 - 其它',
      'Shopkeeper - Personal Care Products': '售貨員 - 個人護理用品',
      'Stockkeeper - Clothing': '理貨員 - 服裝',
      'Stockkeeper - Electric Products': '理貨員 - 電器產品',
      'Stockkeeper - Food': '理貨員 - 食品',
      'Stockkeeper - Household Products': '理貨員 - 家庭用品',
      'Stockkeeper - Luxury': '理貨員 - 奢侈品',
      'Stockkeeper - Others': '理貨員 - 其它',
      'Stockkeeper - Personal Care Products': '理貨員 - 個人護理用品',
      'Subtitle - Others': '其它',
      'Subtitle - Shopkeeper': '售貨員',
      'Subtitle - Stockkeeper': '理貨員',
    },
    'Sales / Agents': {
      'Business Promotion': '業務推廣',
      'Food Wholesales': '食品批發',
      'General Sales': '一般推銷',
      Insurance: '保險',
      'Real Estate': '地產',
    },
  },
  language: {
    cantonese: '廣東話',
    chinese: '中文',
    english: '英文',
    mandarin: '普通話',
  },
  link: {
    aboutMoovup: '關於 Moovup',
    aboutUs: '前線搵工平台',
    back: '返回',
    careerBlogs: '職場網誌',
    communityGuideline: '社群守則',
    contact: '聯絡我們',
    downloadApp: '立即下載應用程式',
    employerFAQ: '僱主常見問題',
    jobAlert: '職位空缺通知',
    jobSearch: '職位空缺搜尋',
    joinOurTeam: '加入我們團隊',
    myResume: '我的履歷表',
    ods: '單日工作模式',
    ourService: '免費招聘平台',
    postJob: '我是僱主',
    privacyPolicy: '私隱政策',
    recruitmentAdvice: '僱主網誌',
    recruitmentPlatform: '刊登招聘',
    temporaryMatching: '臨時工配對服務',
    tnc: '服務條款',
    videos: '短片',
  },
  login: {
    actionMain: '登入/註冊',
    actionOptional: '免費註冊',
    otpMsg: '輸入驗證碼',
    resend: '重新發送驗證碼',
    sendVoice: '嘗試語音通話',
    sentMsg: '已發送驗證碼至',
    title: '求職者註冊/登入',
  },
  logout: {
    actionMain: '登出',
  },
  metadata: {
    description:
      '香港免費前線招聘求職平台，招聘兼職part-time、全職full-time、臨時工、包裝員、文員、倉務員超過4,000份前線職位空缺。餐飲、零售、物流、運輸、包裝生產、活動推廣及客戶服務等行業正在招聘的工作，更可建立你的網上電子履歷表及求職喜好，方便職位配對及接收最新職缺通知。搵工網、搵工app同時適用，立即開始於Moovup好工速遞搵工。',
    job: '工作空缺',
    keyword:
      'job, jobs, job search, find job, post job, work, 搵工, 求職, 招聘, 工作, recruit, recruitment, jobseekers, employment, employment agency, employer, employee, part time, full time, 求職網, 搵工網, 職位空缺, 全職, 兼職, 履歷表, career news, resume, cover letter, CV sample',
    title: '香港免費前線招聘求職平台 | Moovup好工速遞',
    titleSubfix: 'Moovup 好工速遞',
  },
  notification: {
    notificationAllJobs: '全部工作',
    notificationNoUpdate: '沒有消息更新',
    notificationTypeJobAlertMatched: '你有新空缺通知',
    notificationTypeJobClosed: '此職位暫停招聘，如短期內未獲聯絡，可考慮申請其他工作',
    qrCodeAlertReminder:
      '掃一掃 QR code，\n下載 Moovup 好工速遞應用程式，\n即可設定「新空缺通知」，\n每朝為你送上最新筍工！',
    qrCodeNotificationReminder: '掃一掃 QR code，\n下載 Moovup 好工速遞應用程式，\n即可緊貼申請狀態，以及最新好工！',
    title: '通知',
  },
  profile: {
    addLicense: '添加許可證',
    addSkill: '添加技能',
    changeTelPopupContent: '如要更改您的手機號碼，請下載最新版本的Moovup應用程式，然後前往「設定」。',
    createMyProfile: '建立我的履歷',
    createProfile: '建立履歷',
    createProfileHintContent: '5個簡單步驟提高受聘機會',
    createProfileHintTitle: '大量空缺等著你！',
    createProfilePopupContent: '去建立一個！',
    editProfile: '編輯我的履歷',
    education: '教育程度',
    fillInName: '填寫姓名',
    headshotDesc: '請選擇能清楚展示你正面的專業照片',
    headshotWarning: '🤗 避免使用濾鏡、團體合照或者複雜背景的相片',
    inputLanguage: '輸入語言',
    inputLicensesHint: '例如：駕駛執照，雅思，急救',
    inputSkillHint: '例如：縫紉，設計',
    introductionHint: '告訴我一些關於你的事',
    languageSkill: '語言能力',
    licenseNSkill: '資格和許可證',
    messageToEmployer: '給僱主的訊息',
    messageToEmployerHintContent: '你可以簡短地描述你的工作經驗和性格',
    messageToEmployerHintTitle: '想讓僱主了解你更多？',
    messageToEmployerHintTypo: '🤗 記得檢查錯別字',
    messageToEmployerPlaceholder: '開始輸入',
    messageToEmployerPromptContent: '你可留一個簡短的訊息給僱主，以加深僱主對你的第一印象',
    messageToEmployerPromptTitle: '想讓僱主更了解你？',
    myProfile: '我的履歷',
    newToWork: '沒有工作經驗',
    otherSkill: '其他技能',
    qualificationTitle: '許可證和技能',
    skipWritingMessageToEmployer: '稍後再填',
    spokenSkills: '口語',
    upload: '上載',
    workExperienceTitle: '工作經驗',
    writeMessageToEmployer: '填寫訊息',
    writtenSkills: '書寫',
  },
  search: {
    advance: '進階搜尋',
    filterAfternoon: '午更',
    filterDayTime: '早更',
    filterDistrict: '地區',
    filterEmploymentType: '受聘模式',
    filterEvening: '晚更',
    filterJobCategory: '工作種類',
    filterOvernight: '通宵更',
    filterSalaryMonthlyRate: '月薪 (全職)',
    filterSalaryPartTimeHourlyRate: '時薪 (兼職)',
    filterSalaryTempHourlyRate: '時薪 (臨時工)',
    filterTimeWeekdays: '僅平日',
    filterTimeWeekend: '僅週末',
    filterViewResult: '查看 {count} 結果',
    filterWorkingDay: '上班日子',
    filterWorkingTime: '上班時間',
    hint: '搜索',
    jobAlertMaxDayPerWeek: '每日{number}小時',
    jobAlertMaxHourPerDay: '每週{number}日',
    // eslint-disable-next-line no-template-curly-in-string
    jobAlertMoneyPerHour: '${number}+/小時',
    // eslint-disable-next-line no-template-curly-in-string
    jobAlertMoneyPerMonth: '${number}K+/月',
    metaDescription: ' | Moovup 好工速遞 | 搵工平台 | 電子CV 一撳申請!',
    metaDistrictDescription:
      '尋找香港超過{numberOfJobs}份{district}區的職位空缺，求職者可即時申請正在招聘的心水筍工，更可建立你的網上電子履歷表及求職喜好，方便職位配對及接收最新請人職缺通知。搵工網、搵工app同時適用，立即開始於Moovup 好工速遞搵工。',
    metaDistrictTitle: '{numberOfJobs}個{district}招聘，{searchDate}職位空缺招聘 | Moovup 好工速遞',
    metaEmploymentOrJobTypeDescription:
      '尋找香港超過{numberOfJobs}份{employmentType}{jobType}的職位空缺，求職者可即時申請正在招聘的心水筍工，更可建立你的網上電子履歷表及求職喜好，方便職位配對及接收最新請人職缺通知。搵工網、搵工app同時適用，立即開始於Moovup好工速遞搵工。',
    metaEmploymentOrJobTypeTitle: '{employmentType}{jobType}招聘，{numberOfJobs}個職位空缺招聘 | Moovup 好工速遞',
    metaKeyword:
      '全職, 兼職, 臨時工, 搵工, 求職, 招聘, 工作, recruit, recruitment, jobseekers, employment, employment agency, employer, employee, part time, full time, 求職網, 搵工網, 職位空缺',
    metaKeywordDistrict:
      '屯門, 元朗, 天水圍, 油尖旺, 灣仔, 銅鑼灣, 新界西, 新界東, 香港島, 九龍, 大嶼山, 機場, 搵工, 求職, 招聘, 工作, recruit, recruitment, jobseekers, employment, employment agency, employer, employee, part time, full time, 求職網, 搵工網, 職位空缺, 全職, 兼職, 臨時工',
    metaTitleSubfix: 'Moovup 好工速遞 | 搵工平台',
    nearByDisable:
      '如想搜尋附近工作，請允許使用目前位置。\n步驟：\n1) 開啟瀏覽器設定 > 隱私設置和安全性 > 網頁設定 > 位置\n2) 允許使用目前位置\n',
    resultCompanyJobsTitle: '{total}個{company}的職位空缺',
    resultParamTitle: '{title}的職位空缺 | 共{total}個{title}的職位空缺 | 共{total}個{title}的職位空缺',
    resultTitle: '{total}個職位空缺',
    selectAll: '未指定',
  },
  seeker: {
    title: '求職者平台',
  },
  sideMenu: {
    imEmployer: '我是僱主',
    learnMore: '了解更多',
    login: '登入',
    personalizedJobExperience: '開始你的個人化求職體驗',
    postJob: '刊登工作',
  },
  signup: {
    consentAgree: '我同意',
    consentAnd: '及',
  },
  skill: {
    chinese_word_processing: '中文輸入',
    driving: '駕駛',
    microsoft_excel: '微軟 Excel',
    microsoft_word: '微軟 Word',
  },
  workexp: {
    addWorkExp: '新增工作經驗',
    companyName: '公司名稱',
    employmentType: '受僱形式：',
    endDate: '結束日期',
    fillWorkExp: '填寫工作經驗',
    jobCategory: '工作種類',
    jobDescription: '職位描述',
    jobDescriptionHint: '🤗 填寫詳細的職位描述（例如：職責）能助你更快配對合適工作',
    jobPosition: '工作職位',
    month: '月',
    now: '現在',
    positionName: '職位名稱',
    removeWorkExperienceContent: '你確定要刪除這個記錄？',
    removeWorkExperienceTitle: '刪除記錄',
    startDate: '開始日期',
    workExperienceHintContent: '你可以隨時在「我的履歷」新增工作經驗',
    workExperienceHintTitle: '暫時略過？',
    year: '年',
  },
}
