import { findDistrictByShortCode, sortDistricts } from '../../mapping/district'
import { findEmploymentTypeFieldByName, sortEmploymentTypeNames } from '../../mapping/employmentType'
import { findJobTypeByShortCode, sortJobTypes } from '../../mapping/jobType'
import _isNil from 'lodash/isNil'
import _omitBy from 'lodash/omitBy'
import { isLatLngValid } from '../../location'
import { PLATFORM_GOOGLE_ANALYTICS_4 } from '../../../plugins/eventTracker'
import { removeEmptyField } from '../../object'
import { shiftIDToTrackingIDMapping } from '../../mapping/shiftTime'

function sendEvent(eventTracker, eventName, eventParams) {
  eventTracker.sendCustomEvent(eventName, eventParams, [PLATFORM_GOOGLE_ANALYTICS_4])
}

function convertCertOrSkillToStr(certs) {
  if (certs) {
    return certs.map((cert) => cert.name).join(',')
  }
}

function convertSkillToStr(skills) {
  if (skills) {
    const strArr = []
    skills.forEach((skill) => strArr.push(`${skill.name}::${skill.level}`))
    return strArr.join(',')
  }
}

function covertWorkExperienceToJson(workExps) {
  if (workExps) {
    if (workExps.length === 0) {
      return ''
    }
    return JSON.stringify(
      workExps.map((workExp) => {
        return {
          company_name: workExp.company_name,
          from: `${workExp.start_date}T00:00:00Z`,
        }
      }),
    )
  }
}

export function profileToTrackingData(profile, isSkipNull = false) {
  let profileData = {
    certificates: convertCertOrSkillToStr(profile.certs),
    education: profile.education,
    name: profile.name,
    profile_img: profile.thumbnail,
    self_intro: profile.messageToEmployer,
    skills: convertCertOrSkillToStr(profile.skills),
    spoken_language: convertSkillToStr(profile.spokenSkills),
    work_experience: covertWorkExperienceToJson(profile.workExperiences),
    written_language: convertSkillToStr(profile.writtenSkills),
  }
  if (isSkipNull) {
    profileData = _omitBy(profileData, _isNil)
  }
  return profileData
}

let impressionSessionMap = {}

function getSession(screenSessionId) {
  // Create a session log
  if (!impressionSessionMap[screenSessionId]) {
    impressionSessionMap[screenSessionId] = {}
  }
  return impressionSessionMap[screenSessionId]
}

/* eslint-disable camelcase */

/**
 * Get search params for tracking
 */
export function getTrackingSearchParams(params = {}) {
  // Map district IDs into sorted names,
  // then join into string
  let district = ''
  if (Array.isArray(params.district)) {
    district = sortDistricts(params.district.map(findDistrictByShortCode))
      .map(({ name } = {}) => name)
      .filter((name) => name)
      .join(',')
  }

  // Map employment types into sorted tracking names,
  // then join into string
  let employment_type = ''
  if (Array.isArray(params.employment)) {
    employment_type = sortEmploymentTypeNames(params.employment)
      .map((employment) => findEmploymentTypeFieldByName(employment, 'trackingName'))
      .join(',')
  }

  // Map job type IDs into sorted short codes,
  // then join into string
  let job_type = ''
  if (Array.isArray(params.jobType)) {
    job_type = sortJobTypes(params.jobType.map(findJobTypeByShortCode))
      .map(({ shortCode } = {}) => shortCode)
      .filter((sc) => sc)
      .join(',')
  }

  return {
    district,
    employment_type,
    hourly_rate: params.hourlyRate,
    job_type,
    latlon: isLatLngValid(params.latitude, params.longitude) ? `${params.latitude},${params.longitude}` : '',
    month_rate: params.monthlyRate,
    search_term: params.q,
    shift_type: Array.isArray(params.workingHour)
      ? params.workingHour.map((shiftId) => shiftIDToTrackingIDMapping[shiftId]).join(',')
      : '',
    weekday: params.workingDay ? shiftIDToTrackingIDMapping[params.workingDay] : null,
  }
}

/* eslint-enable camelcase */

export function clearSession() {
  impressionSessionMap = {}
}

function _sendJobImpressionEvent(eventTracker, eventName, eventData = {}) {
  const session = getSession(eventData.sessionId)
  const jobId = eventData.jobId
  if (!session[jobId]) {
    session[jobId] = true
    const trackingSearchParams = eventData.trackingSearchParams || {}
    const data = {
      ...trackingSearchParams,
      job_id: jobId,
      position: eventData.position,
      screen_session: eventData.sessionId,
      source: eventData.source,
      source_section: eventData.sourceSection,
    }
    sendEvent(eventTracker, eventName, removeEmptyField(data))
  }
}

/**
 * Send only one job ad impression within one session
 * @param eventTracker
 * @param eventData {{jobId: string, source: string, sourceSection: string, position: number, trackingSearchParams: object, sessionId: string  }}
 */
export function sendJobAdImpressionEvent(eventTracker, eventData = {}) {
  _sendJobImpressionEvent(eventTracker, 'job_ads_impression', eventData)
}

/**
 * Send only one job impression within one session
 * @param eventTracker
 * @param eventData {{jobId: string, source: string, sourceSection: string, position: number, trackingSearchParams: object, sessionId: string  }}
 */
export function sendJobImpressionEvent(eventTracker, eventData = {}) {
  _sendJobImpressionEvent(eventTracker, 'job_impression', eventData)
}

/**
 * Send home banner impression event within one session
 * @param eventTracker
 * @param eventData {{link: string, source: string, position: number, imageUrl: string, sessionId: string  }}
 */
export function sendHomeBannerImpressionEvent(eventTracker, eventData = {}) {
  const session = getSession(eventData.sessionId)
  const imageUrl = eventData.imageUrl
  if (!session[imageUrl]) {
    session[imageUrl] = true
    sendEvent(eventTracker, 'banner_impression', {
      image_url: imageUrl,
      link: eventData.link,
      position: eventData.position,
      screen_session: eventData.sessionId,
      source: eventData.source,
    })
  }
}

function _sendJobClickEvent(eventTracker, eventName, eventData = {}) {
  const trackingSearchParams = eventData.trackingSearchParams || {}
  const jobId = eventData.jobId
  const data = {
    ...trackingSearchParams,
    job_id: jobId,
    position: eventData.position,
    screen_session: eventData.sessionId,
    source: eventData.source,
    source_section: eventData.sourceSection,
  }
  sendEvent(eventTracker, eventName, removeEmptyField(data))
}

/**
 * Send job ad click event
 * @param eventTracker
 * @param eventData {{jobId: string, source: string, sourceSection: string, position: number, trackingSearchParams: object, sessionId: string }}
 */
export function sendJobAdClickEvent(eventTracker, eventData = {}) {
  _sendJobClickEvent(eventTracker, 'job_ads_click', eventData)
}

/**
 * Send job click event
 * @param eventTracker
 * @param eventData {{jobId: string, source: string, sourceSection: string, position: number, trackingSearchParams: object, sessionId: string  }}
 */
export function sendJobClickEvent(eventTracker, eventData = {}) {
  _sendJobClickEvent(eventTracker, 'job_click', eventData)
}

export function sendApplyJobClickEvent(eventTracker, jobId) {
  sendEvent(eventTracker, 'apply_job_click', {
    job_id: jobId,
  })
}

/**
 * Send job ads request
 * @param eventTracker
 * @param eventData {{source: string, trackingSearchParams: object, sessionId: string }}
 */
export function sendJobAdsRequestEvent(eventTracker, eventData = {}) {
  const trackingSearchParams = eventData.trackingSearchParams || {}
  const data = {
    ...trackingSearchParams,
    screen_session: eventData.sessionId,
    source: eventData.source,
  }
  sendEvent(eventTracker, 'job_ads_request', removeEmptyField(data))
}

/**
 * Send filter apply event
 * @param eventTracker
 * @param eventData {{source: string, trackingSearchParams: object}}
 */
export function sendFilterApplyEvent(eventTracker, eventData = {}) {
  const trackingSearchParams = eventData.trackingSearchParams || {}
  const data = {
    ...trackingSearchParams,
    source: eventData.source,
  }
  sendEvent(eventTracker, 'filter_apply', removeEmptyField(data))
}

/**
 * Send apply job complete event
 * @param eventTracker
 * @param eventData {{jobId: string, profileTrackingData: object, selectedLocationIds: string}}
 */
export function sendApplyJobCompleteEvent(eventTracker, eventData = {}) {
  const profileTrackingData = eventData.profileTrackingData || {}
  const data = {
    ...profileTrackingData,
    job_id: eventData.jobId,
    selected_locations: eventData.selectedLocationIds,
  }
  sendEvent(eventTracker, 'apply_job_complete', data)
}

export function sendResendSmsEvent(eventTracker, phoneNumber) {
  sendEvent(eventTracker, 'resent_sms_verification', {
    phone_number: phoneNumber,
  })
}

export function sendResendVoiceEvent(eventTracker, phoneNumber) {
  sendEvent(eventTracker, 'resent_voice_verification', {
    phone_number: phoneNumber,
  })
}

export function sendLoginCompleteEvent(eventTracker, methods) {
  sendEvent(eventTracker, 'login_complete', {
    login_method: methods,
  })
}

export function sendAccountSectionClickEvent(eventTracker, section) {
  sendEvent(eventTracker, 'my_account_section_click', {
    section,
  })
}

export function sendLogoutEvent(eventTracker) {
  sendEvent(eventTracker, 'logout')
}

/**
 * Send job query event
 * @param eventTracker
 * @param eventData {{searchTerm: string, source: string}}
 */
export function sendJobQueryEvent(eventTracker, eventData = {}) {
  sendEvent(eventTracker, 'query', {
    search_term: eventData.searchTerm,
    source: eventData.source,
  })
}

export function sendUpdateLanguageEvent(eventTracker, language) {
  sendEvent(eventTracker, 'update_display_language', {
    language,
  })
}

export function sendUpdateProfileEvent(eventTracker, profile) {
  sendEvent(eventTracker, 'update_profile_info', profileToTrackingData(profile, true))
}

export function sendHomeCategoryClickEvent(eventTracker, type) {
  sendEvent(eventTracker, 'home_category_click', {
    type,
  })
}

/**
 * Send filter reset click event
 * @param eventTracker
 * @param eventData {{type: string, source: string}}
 */
export function sendFilterResetClickEvent(eventTracker, eventData = {}) {
  sendEvent(eventTracker, 'filter_reset_click', {
    source: eventData.source,
    type: eventData.type,
  })
}

/**
 * Send home banner click event
 * @param eventTracker
 * @param eventData {{link: string, source: string, position: number, imageUrl: string, sessionId: string }}
 */
export function sendHomeBannerClickEvent(eventTracker, eventData = {}) {
  sendEvent(eventTracker, 'banner_click', {
    image_url: eventData.imageUrl,
    link: eventData.link,
    position: eventData.position,
    screen_session: eventData.sessionId,
    source: eventData.source,
  })
}

/**
 * Send feature list more click event
 * @param eventTracker
 * @param eventData {{listId: string, listName: string, totalCount: number }}
 */
export function sendFeatureListMoreClickEvent(eventTracker, eventData = {}) {
  sendEvent(eventTracker, 'feature_list_more_click', {
    list_id: eventData.listId,
    list_name: eventData.listName,
    total_count: eventData.totalCount,
  })
}

export function sendMyApplicationSectionEvent(eventTracker, section) {
  sendEvent(eventTracker, 'my_application_section_click', {
    section,
  })
}

export function sendEmployerPortalClickEvent(eventTracker) {
  sendEvent(eventTracker, 'employer_portal_click')
}

export function sendJobLocationMapClickEvent(eventTracker, source) {
  sendEvent(eventTracker, 'job_location_map_click', {
    source,
  })
}

export function sendMyApplicationClickEvent(eventTracker, jobId) {
  sendEvent(eventTracker, 'my_application_click', {
    job_id: jobId,
  })
}

/**
 * Send featured company impression event
 * @param eventTracker
 * @param eventData {{company_id: string, source: string, position: number}}
 */
export function sendFeaturedCompanyImpressionEvent(eventTracker, eventData = {}) {
  sendEvent(eventTracker, 'feature_company_impression', {
    company_id: eventData.company_id,
    position: eventData.position,
    source: eventData.source,
  })
}

/**
 * Send featured company click event
 * @param eventTracker
 * @param eventData {{company_id: string, source: string, position: number}}
 */
export function sendFeaturedCompanyClickEvent(eventTracker, eventData = {}) {
  sendEvent(eventTracker, 'feature_company_click', {
    company_id: eventData.company_id,
    position: eventData.position,
    source: eventData.source,
  })
}

/**
 * Send featured company list more click event
 * @param eventTracker
 * @param eventData {{source: string}}
 */
export function sendFeaturedCompanyListMoreClickEvent(eventTracker, eventData = {}) {
  sendEvent(eventTracker, 'feature_company_list_more_click', {
    source: eventData.source,
  })
}

/**
 * Send restore deleted account click event
 * @param eventTracker
 */
export function sendAccountDeletionRestoreClickEvent(eventTracker) {
  sendEvent(eventTracker, 'account_deletion_restore_click')
}
