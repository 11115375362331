import { getMailJetPageURL } from '../../locales/mailjetPages'
/**
 * Mixin for employer pages navigation
 */
export default {
  computed: {
    /**
     * Employer action items
     */
    actionItems() {
      return [
        {
          linkPath: this.localePathName('/'),
          title: this.$t('seeker.title'),
        },
        // Map locale link data into item format
        ...this.altLocaleLinks.map((item = {}) => ({
          ...item,
          title: item.titleShort, // Use shortened name
        })),
      ]
    },

    /**
     * Employer page items
     */
    pageItems() {
      return [
        {
          // Always link to the page in Chinese
          href: getMailJetPageURL('employer/bidding', 'zh-hant'),
          title: this.$t('employer.bidding'),
        },
        {
          // Always link to the page in Chinese
          href: getMailJetPageURL('employer/faq', 'zh-hant'),
          title: this.$t('employer.faq'),
        },
      ]
    },
  },
}
