/**
 * Screen name mapping object based on the route change.
 * page-id is depended on Nuxt.js name convention for pages folder. We cannot modify it
 * For page routeing, we define two parameters in query
 * `page`
 *  It means a sub page in a single page.
 * `popup`
 *  It means a sub page to display a popup layout
 * The following is mentioning how to handling mapping
 * {
 *  '<page-id>': <screen_name>
 *  '<page-id>': {
 *    'page': {
 *      '<sub-page-id>': <screen_name>,
 *      '<sub-page-id>': {
 *        'default': <screen_name>,
 *        'popup': {
 *          '<popup-id>': <screen_name>,
 *        }
 *      }
 *     }
 *   }
 * }
 */

const screenNameMapping = {
  apply: {
    page: {
      fill_cv: {
        default: 'apply_job.preview',
        popup: {
          education: 'apply_job.education',
          introduction: 'apply_job.self_intro',
          language: 'apply_job.language',
          skill: 'apply_job.skill',
          'user-info': 'apply_job.fill_name',
          'work-exp-detail': 'apply_job.work_exp.detail',
        },
      },
      select_location: 'apply_job.select_location',
    },
  },
  'apply-success': 'apply_job.success',
  'blogs-list-name-page': 'seeker.blogs_list',
  'blogs-name': 'blog',
  'contact-us': 'contact_us',
  'employer-blogs-list-name-page': 'employer.blogs_list',
  'employer-name': 'employer',
  'feature-id': 'feature_list',
  index: 'home',
  'job-slug-id': 'job_detail',
  'jobs-id': 'job_detail',
  'jobseeker-name': 'seeker',
  login: 'login',
  'login-mfa': 'login.otp',
  'privacy-policy': 'privacy_policy',
  profile: {
    page: {
      myApplications: {
        default: 'my_application',
        popup: {
          application: 'my_application.detail',
        },
      },
      myProfile: {
        default: 'profile',
        popup: {
          education: 'profile.education',
          introduction: 'profile.self_intro',
          language: 'profile.language',
          skill: 'profile.skill',
          'user-info': 'profile.update_name',
          'work-exp-detail': 'profile.work_exp.detail',
        },
      },
      notifications: {
        default: 'notification_center',
        popup: {
          application: 'my_application.detail',
        },
      },
    },
  },
  'profile-create-apply': {
    page: {
      education: 'apply_job.education',
      introduction: 'apply_job.self_intro',
      language: 'apply_job.language',
      skill: 'apply_job.skill',
      'user-info': 'apply_job.fill_name',
      'work-exp': {
        default: 'apply_job.work_exp',
        popup: {
          'work-exp-detail': 'apply_job.work_exp.detail',
        },
      },
    },
  },
  'profile-create-profile': {
    page: {
      education: 'profile.education',
      introduction: 'profile.self_intro',
      language: 'profile.language',
      skill: 'profile.skill',
      username: 'profile.update_name',
      'work-exp': {
        default: 'profile.work_exp',
        popup: {
          'work-exp-detail': 'profile.work_exp.detail',
        },
      },
    },
  },
  search: 'search_job_result_list',
  'search-type-name': 'search_job_result_list',
  'terms-and-conditions': 'terms_and_conditions',
}

export function screenNameConvertFunc(route, pageTitle, pagePath) {
  const currentRoute = route
  const query = currentRoute.query
  const mapValue = screenNameMapping[pageTitle]
  if (typeof mapValue === 'object') {
    // For one page with sub page
    if (query.page) {
      const pageVaule = mapValue.page[query.page]
      if (typeof pageVaule === 'object') {
        // For popup
        if (query.popup) {
          return pageVaule.popup[query.popup]
        }
        return pageVaule.default
      }
      return pageVaule || ''
    }
  }

  return mapValue || ''
}
