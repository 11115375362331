const mailJetBaseURL = 'https://info.page.moovup.com/lp/sg703'

/**
 * A list of static pages created with MailJet
 */

const mailJetPagesLocales = {
  'contact-us': {
    en: '/contact',
    'zh-hant': '/contact-cn',
  },
  'employer/bidding': {
    en: '/adbooster',
    'zh-hant': '/adbooster-cn',
  },
  'employer/community-guidelines': {
    en: '/guidelines',
    'zh-hant': '/guidelines-cn',
  },
  'employer/faq': {
    en: '/faq',
    'zh-hant': '/faq-cn',
  },
  'employer/faq-ad-booster': {
    en: '/faq-adbooster',
    'zh-hant': '/faq-adbooster-cn',
  },
  'jobseeker/app': {
    en: '/seeker',
    'zh-hant': '/seeker-cn',
  },
  'jobseeker/blogs/list': {
    en: '/en/blogs-list/career-blogs/',
    'zh-hant': '/blogs-list/職場網誌/',
  },
  'join-our-team': {
    en: '/join-us',
    'zh-hant': '/joinus-cn',
  },
}

export function getMailJetPageURL(page, locale) {
  const path = mailJetPagesLocales[page][locale]
  if (path) {
    return `${mailJetBaseURL}${path}`
  }
  return ''
}
