import { findDistrictByShortCode, getDistrictLocaleKey, sortDistricts } from '../../libs/mapping/district'
import { formatMonthlySalary, groupAllowancesByName, mapShifts } from '../../libs/job'
import { sortJobTypes, translateJobTypeWithFallback } from '../../libs/mapping/jobType'
import _get from 'lodash/get'
import _sortedUniqBy from 'lodash/sortedUniqBy'
import { formatDate } from '../../libs/datetime'
import getRangeStr from '../../libs/getRangeStr'
import { isLatLngValid } from '../../libs/location'

export default {
  computed: {
    addresses() {
      if (this.job && Array.isArray(this.job.address)) {
        return this.job.address
      }
      return []
    },
    addressOnMap() {
      return this.job.address_on_map
    },
    allowances() {
      return groupAllowancesByName(this.job.allowances)
    },
    carrerLevel() {
      return this.job.career_level
    },
    companyName() {
      return this.job.company.name || ''
    },
    /**
     * List of district data
     * @returns {Array}
     */
    districts() {
      // Map job addresses into district data,
      // then sort and return unique
      if (Array.isArray(this.job.address) && this.job.address.length) {
        const sortedDistricts = sortDistricts(
          this.job.address
            .map(({ district_short_code } = {}) => findDistrictByShortCode(district_short_code)) // eslint-disable-line camelcase
            .filter((d) => d),
        )
        return _sortedUniqBy(sortedDistricts)
      }
      return []
    },
    districtsLabel() {
      if (this.districts.length) {
        return this.districts
          .map((district) => this.$t(getDistrictLocaleKey(district)))
          .filter((l) => l)
          .join(this.$t('general.comma'))
      }
      return ''
    },
    employmentType() {
      return _get(this.job, 'employment') || ''
    },
    endDate() {
      if (this.job.end_date) {
        return formatDate(new Date(this.job.end_date))
      }
      return ''
    },
    hourlySalaryBase() {
      return this.job.from_hourly_rate || 0
    },
    hourlySalaryRange() {
      return getRangeStr(this.job.from_hourly_rate, this.job.to_hourly_rate)
    },
    isAlternativeSat() {
      return this.job.alternative_sat
    },
    isJobAd() {
      return this.job.is_ad
    },
    isShiftRequired() {
      return this.job.shift_required
    },
    jobDescription() {
      return this.job.job_description || ''
    },
    jobName() {
      return this.job.job_name || ''
    },
    /**
     * List of job type data
     * @returns {Array}
     */
    jobTypes() {
      if (Array.isArray(this.job.job_types) && this.job.job_types.length) {
        // Sort job type data
        return sortJobTypes(this.job.job_types.filter((jt) => jt))
      }
      return []
    },
    jobTypesLabel() {
      if (this.jobTypes.length) {
        // Need to inject "this" context to i18n callbacks,
        // ref: https://kazupon.github.io/vue-i18n/api/#vue-injected-methods
        return this.jobTypes
          .map((jobType) => translateJobTypeWithFallback(jobType, this.$te.bind(this), this.$t.bind(this)))
          .filter((l) => l)
          .join(this.$t('general.comma'))
      }
      return ''
    },
    locations() {
      if (Array.isArray(this.job.address)) {
        return this.job.address
          .filter((address) => address && address.address && isLatLngValid(address.lat, address.lng))
          .map((address) => {
            return {
              address: address.address,
              lat: address.lat,
              lng: address.lng,
            }
          })
      }
      return []
    },
    monthlySalaryBase() {
      return formatMonthlySalary(this.job.from_monthly_rate)
    },
    monthlySalaryRange() {
      const fromSalary = formatMonthlySalary(this.job.from_monthly_rate)
      const toSalary = formatMonthlySalary(this.job.to_monthly_rate)

      if (!fromSalary) return ''
      if (fromSalary === toSalary) return `${fromSalary}K`
      return `${fromSalary}K${toSalary ? '-' + toSalary + 'K' : ''}`
    },
    relativePostedDate() {
      if (this.job.published_at) {
        const diffBetweenNowAndPostedDate = new Date() - new Date(this.job.published_at)
        const diffInDays = Math.floor(diffBetweenNowAndPostedDate / (1000 * 60 * 60 * 24))
        if (diffInDays < 3) {
          return this.$t('jobDetail.postedJustNow')
        } else if (diffInDays < 7) {
          return this.$t('jobDetail.postedThisWeek')
        } else if (diffInDays < 30) {
          return this.$t('jobDetail.postedAWeekAgo')
        } else if (diffInDays < 60) {
          return this.$t('jobDetail.postedAMonthAgo')
        } else if (diffInDays < 90) {
          return this.$t('jobDetail.postedTwoMonthsAgo')
        } else if (diffInDays < 120) {
          return this.$t('jobDetail.postedThreeMonthsAgo')
        } else {
          return this.$t('jobDetail.postedMoreThanThreeMonthsAgo')
        }
      }
      return ''
    },
    shifts() {
      return mapShifts(this.job.working_hour)
    },
    startDate() {
      if (this.job.start_date) {
        return formatDate(new Date(this.job.start_date))
      }
      return ''
    },
    vacancy() {
      return this.job.vacancy || 0
    },
    workingDayRange() {
      return getRangeStr(this.job.from_working_days_per_week, this.job.to_working_days_per_week)
    },
    workingHourRange() {
      return getRangeStr(this.job.from_working_hours_per_day, this.job.to_working_hours_per_day)
    },
  },
  props: {
    job: {
      required: true,
      type: Object,
    },
  },
}
